import React, { useEffect, useRef, useState } from "react";
import TopBar from "../../../ADMIN/components/Home/topbar/Topbar";
import FarmerTopBar from "../topbar/Topbar";
import "./home.css";
import { Link, useNavigate } from "react-router-dom";
import { GiFarmTractor } from "react-icons/gi";
import {
  fetchFarmDetails,
  fetchIssuesDetails,
  fetchDamagesDetails,
  fetchOperations,
  fetchPlan,
  fetchAcademies,
  fetchActivePlan,
  fetchDiseaseDetails,
  fetchNews,
} from "../../fetches/FetchFarmDetails";
import { GoIssueOpened } from "react-icons/go";
import MyFarms from "../../account/farms/myFields/MyFarms";
import HomeMap from "../../account/farms/myFields/HomeMap";
import { SiForestry } from "react-icons/si";
import CropCircle from "./charts/Pie";
import { getWeatherData } from "../../account/weather/StatesWeather";
import TemperatureGraph from "../../account/weather/charts/TemperatureGraph";
import HomeTemp from "../../account/weather/charts/HomeTemp";
import { WiRain } from "react-icons/wi";
import { WeatherCard } from "../../account/weather/FinalWeatherList";
import { GrFormNext } from "react-icons/gr";
import { listFarmers, listPackageRequests } from "../../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import FeatureAlert from "../../account/subscriptions/alerts/SubsAlert";
import FarmSlider from "./farms/FarmSlider";
import WeatherSlider from "./farms/WeatherSlider";
import { toast } from "react-toastify";
import NoDataComponent from "../../null/NullData";
import { FaBookOpen, FaNewspaper } from "react-icons/fa";
import NoNewsComponent from "../../null/NoNews";
import { useTranslation } from "react-i18next";
import ActivePlan from "./farms/ActivePlan";
export function WeatherIcon({ temperature }) {
  if (temperature >= 30)
    return (
      <svg
        class="weather-icon"
        data-src="/images/weathericons/1.svg"
        viewBox="0 0 288 288"
        width="88"
        height="88"
      >
        <g stroke="#FF8700" stroke-width="9.6" fill="none" fill-rule="evenodd">
          <path d="M144 0v48M144 240v48M0 144h48M211.872 76.128l33.936-33.936M245.808 245.808l-33.936-33.936M76.128 76.128 42.192 42.192"></path>
          <circle cx="144" cy="144" r="76.8"></circle>
          <path d="m76.128 211.872-33.936 33.936M240 144h48"></path>
        </g>
      </svg>
    );
  if (temperature >= 20)
    return (
      <svg
        class="weather-icon"
        data-src="/images/weathericons/3.svg"
        viewBox="0 0 288 288"
        width="88"
        height="88"
      >
        <g stroke-width="9.593" fill="none" fill-rule="evenodd">
          <path
            d="M143.898 21v47.966M0 164.898h47.966M211.722 97.074l33.912-33.912M76.074 97.074 42.162 63.162M42.162 266.634l33.912-33.912M220.356 159.142c-2.666-35.73-29.7-64.861-65.133-70.183-35.433-5.322-69.833 14.581-82.878 47.952-13.045 33.37-1.264 71.327 28.384 91.446"
            stroke="#FF8700"
          ></path>
          <path
            d="M239.83 169.695h-7.53a52.763 52.763 0 0 0-92.958 28.78h-9.834c-15.894 0-28.78 12.885-28.78 28.78 0 15.894 12.886 28.779 28.78 28.779h110.323c23.841 0 43.169-19.328 43.169-43.17 0-23.841-19.328-43.17-43.17-43.17Z"
            stroke="#BABABA"
          ></path>
        </g>
      </svg>
    );
  if (temperature >= 10)
    return (
      <svg
        class="weather-icon"
        data-src="/images/weathericons/7.svg"
        viewBox="0 0 288 288"
        width="88"
        height="88"
      >
        <path
          d="M264 157.15a57.5 57.5 0 0 0-57.5-57.5h-9.75c-18.372-21.63-47.985-30.027-74.977-21.26-26.99 8.767-46.018 32.963-48.173 61.26H61.55c-20.71 0-37.5 16.79-37.5 37.5s16.79 37.5 37.5 37.5h145c31.737-.028 57.45-25.763 57.45-57.5Z"
          stroke="#BABABA"
          stroke-width="10"
          fill="none"
          fill-rule="evenodd"
        ></path>
      </svg>
    );
  return <WiRain />;
}
const FarmerHome = () => {
  const { t } = useTranslation();
  const [news, setNews] = useState([]);
  const navigate = useNavigate();
  const history = useNavigate(); // Initialize the history object
  const userEmail = localStorage.getItem("farmerEmail");

  const cutDescription = (description) => {
    if (description.length > 30) {
      return description.substring(0, 30) + "...";
    } else if (description.length < 30) {
      return description;
    } else {
      return description;
    }
  };

  const [farms, setfarms] = useState([]);
  const [issues, setIssues] = useState([]);
  const [damages, setDamages] = useState([]);
  const [operations, setOperations] = useState([]);
  const [change, setChange] = useState(false);
  const [polygon, setPolygon] = useState(null);
  const [weatherData, setWeatherData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [plan, setPlan] = useState([]);
  const [shouldDisplayWeather, setShouldDisplayWeather] = useState(false);
  const [courses, setCourses] = useState([]);
  const [activePlan, setActivePlan] = useState(null);
  const [areaLimit, setAreaLimt] = useState(100);
  const [diseases, setDiseases] = useState([]);
  const [profileDetails, setProfileDetails] = React.useState({});
  const [loadingFarmDetails, setLoadingFarmDetails] = useState(true);

  useEffect(() => {
    fetchProfileDetails();

    if (profileDetails.length > 0) {
      //if isDisabled is false, proceed to /farmer
      // Assuming isDisabled is a property in the user data
      const isDisabled = profileDetails[0].isDisabled;

      // Check if the user is not disabled
      if (!isDisabled) {
        toast.success("Account OK");
      } else {
        // Handle the case where the user is disabled (optional)
        toast.error("Account disabled");
        localStorage.clear();
        navigate("/");
      }
    }
  }, [change]);
  //fetch profile  details
  const fetchProfileDetails = async () => {
    try {
      const profileDetails = await API.graphql(
        graphqlOperation(listFarmers, {
          filter: {
            email: {
              eq: userEmail,
            },
          },
        })
      );

      const profileDetailsData = profileDetails.data.listFarmers.items;
      setProfileDetails(profileDetailsData);
      console.log(profileDetailsData);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    async function fetchData() {
      const coursesData = await fetchAcademies();
      setCourses(coursesData);
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const farmDetailsData = await fetchFarmDetails();
      sessionStorage.setItem("farmDetails", JSON.stringify(farmDetailsData));
      setfarms(farmDetailsData);

      // Extract the latitude and longitude from the first farm's rings array
      if (farmDetailsData.length > 0) {
        const firstFarm = farmDetailsData[0];
        const rings = JSON.parse(firstFarm.rings[0]);
        const firstCoordinate = rings[0];
        setPolygon({
          latitude: firstCoordinate.latitude,
          longitude: firstCoordinate.longitude,
        });
      }
    }

    const cachedData = sessionStorage.getItem("farmDetails");
    if (cachedData) {
      setfarms(JSON.parse(cachedData));
    } else {
      fetchData();
    }

    setLoadingFarmDetails(false);
  }, []);

  useEffect(() => {
    console.log(polygon);
  }, [polygon]);

  const farmDetailsData = farms.map((farm) => {
    const farmData = JSON.parse(farm.rings)[0];
    return {
      ...farm,
      lat: farmData.latitude,
      lon: farmData.longitude,
    };
  });

  console.log(farmDetailsData);
  console.log("vhjvchdsbhjbshbjhbsh");

  console.log(weatherData);

  //fetch current plan

  useEffect(() => {
    async function fetchData() {
      const activePlanData = await fetchActivePlan();
      setActivePlan(activePlanData);
      // setAreaLimt(activePlanData[0].areaLimit)
    }
    fetchData();
  }, []);

  console.log(activePlan);

  useEffect(() => {
    async function fetchData() {
      const issuesData = await fetchIssuesDetails();
      setIssues(issuesData);
      sessionStorage.setItem("issuesDetails", JSON.stringify(issuesData));
    }

    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const damagesData = await fetchDamagesDetails();

      setDamages(damagesData);
      sessionStorage.setItem("damagesDetails", JSON.stringify(damagesData));
    }

    fetchData();
  }, []);

  //fetch operations
  useEffect(() => {
    async function fetchData() {
      const operationsData = await fetchOperations();
      setOperations(operationsData);
    }
    fetchData();
  }, []);

  //fetch pests and diseases
  useEffect(() => {
    async function fetchData() {
      const operationsData = await fetchDiseaseDetails();
      setDiseases(operationsData);
      sessionStorage.setItem("diseasesDetails", JSON.stringify(operationsData));
    }

    fetchData();
  }, []);
  //count  faarms
  const countFarms = () => {
    return farms.length;
  };

  //count issues
  const countIssues = () => {
    return issues.length;
  };

  //count issues that are pending
  const countPendingIssues = () => {
    return issues.filter((issue) => issue.status === "pending").length;
  };

  //count issues that are solved
  const countSolvedIssues = () => {
    return issues.filter((issue) => issue.status === "resolved").length;
  };

  //count damages
  const countDamages = () => {
    return damages.length;
  };

  //count diseases
  const countDiseases = () => {
    return diseases.length;
  };

  //count damages that are pending
  const countPendingDamages = () => {
    return damages.filter((damage) => damage.status === "pending").length;
  };

  //count damages that are resolved
  const countResolvedDamages = () => {
    return damages.filter((damage) => damage.status === "resolved").length;
  };

  //count diseases thate are pending
  const countPendingDiseases = () => {
    return diseases.filter((disease) => disease.status === "pending").length;
  };

  //count diseases that are resolved
  const countResolvedDiseases = () => {
    return diseases.filter((disease) => disease.status === "resolved").length;
  };

  //count operations
  const countOperations = () => {
    return operations.length;
  };

  const countFertilization = () => {
    return operations.filter(
      (operation) => operation.operation === "Fertilization"
    ).length;
  };

  const tottalArea = farms
    .reduce((sum, details) => sum + details.area, 0)
    .toFixed(2);

  console.log(tottalArea);
  console.log(farms);

  const handlePointClick = (coordinates) => {};

  useEffect(() => {
    async function fetchData() {
      const issuesData = await fetchPlan();
      setPlan(issuesData);
      setAreaLimt(issuesData[0].areaLimit);
    }
    fetchData();
  }, []);

  console.log("plans", plan);

  useEffect(() => {
    const hasApprovedMeteobluePlan = plan?.some(
      (p) =>
        p.package.title === "Virtual Weather Station" && p.status === "approved"
    );

    setShouldDisplayWeather(hasApprovedMeteobluePlan);
  }, [plan]);

  console.log(plan);

  useEffect(() => {
    if (tottalArea && tottalArea !== null) {
      // Check if activePlan and tottalArea are not null
      // if (tottalArea > areaLimit) {
      //   toast.error(
      //     `You have exceeded the area limit of your current plan. Please upgrade your plan or request additional area limit to continue using the app.`,
      //     {
      //       position: 'top-center',
      //       autoClose: false,
      //       hideProgressBar: false,
      //     }
      //   )
      //   // toast.error(t('areaLimitExceededMessage'), {
      //   //   position: 'top-center',
      //   //   autoClose: false,
      //   //   hideProgressBar: false,
      //   // })
      //   // navigate("/farmer/account/subscription"); // Redirect to subscription page
      // }
    }
  }, [tottalArea]);

  //fetch news
  useEffect(() => {
    async function fetchData() {
      const newsData = await fetchNews();
      setNews(newsData);
    }

    fetchData();
  }, []);

  return (
    <div className="home-container">
      <FarmerTopBar />
      <div className="home-wrapper">
        <div className="left-of-news" style={{ padding: "0 10px" }}>
          <section className="highlight-card">
            <div className="highlight-card-details">
              <div onClick={() => navigate("/myfarm")}>
                <h3>{t("farms")}</h3>
                <h2>{countFarms()}</h2>
                <p>{tottalArea} ha</p>
              </div>
              <div className="highlight-card__icon farm">
                <GiFarmTractor />
              </div>
            </div>
            <div className="highlight-card-details">
              <div onClick={() => navigate("/issues")}>
                <h3>{t("issues")}</h3>
                <h2>{countIssues()}</h2>
                <div className="highlight-card-details-footer">
                  <p>
                    {t("resolved")}({countSolvedIssues()})
                  </p>
                  <p>
                    {t("pending")}({countPendingIssues()})
                  </p>
                </div>
              </div>
              <div className="highlight-card__icon issues">
                <GoIssueOpened />
              </div>
            </div>
            <div className="highlight-card-details">
              <div onClick={() => navigate("/damages")}>
                <h3>{t("damages")}</h3>
                <h2>{countDamages()}</h2>
                <div className="highlight-card-details-footer">
                  <p>
                    {t("resolved")}({countResolvedDamages()})
                  </p>
                  <p>
                    {t("pending")}({countPendingDamages()})
                  </p>
                </div>
              </div>
              <div className="highlight-card__icon damages">
                <SiForestry />
              </div>
            </div>
            <div className="highlight-card-details">
              <div onClick={() => navigate("/diseases")}>
                <h3>Pests & disease</h3>
                <h2>{countDiseases()}</h2>
                <div className="highlight-card-details-footer">
                  <p>
                    {t("resolved")}({countResolvedDiseases()})
                  </p>
                  <p>
                    {t("pending")}({countPendingDiseases()})
                  </p>
                </div>
              </div>
              <div className="highlight-card__icon">
                <GiFarmTractor />
              </div>
            </div>
          </section>
          <section className="home-map" style={{ height: "48vh" }}>
            <HomeMap polygons={farms} handlePointClick={handlePointClick} />
          </section>
          <section className="home-bottom-section">
            <FarmSlider
              polygons={farms}
              issues={issues}
              loadingFarmDetails={loadingFarmDetails}
            />

            <WeatherSlider
              farmDetailsData={farms}
              shouldDisplayWeather={shouldDisplayWeather}
            />
          </section>

          <ActivePlan activePlan={activePlan} />
        </div>
        <div className="news-sidebar">
          <section className="latest-news">
            <div className="latest-header">
              <div className="feeds-header">
                <h2>{t("latestNews")}</h2>
                <Link to="/farmer/news" className="view-all-btn">
                  {t("viewAll")}
                </Link>
              </div>
            </div>
            <div className="latest-news-container">
              {news && news.length === 0 && (
                <NoNewsComponent
                  pTag={t("noCoursesAvailable")}
                  icon={
                    <FaNewspaper
                      size={48}
                      color="#339475"
                      style={{ display: "inline" }}
                    />
                  }
                  h2Tag={t("noCoursesTitle")}
                />
              )}
              {news &&
                news.slice(0, 4).map((item, index) => (
                  <Link
                    to={`/farmer/news/details/${item.id}`}
                    key={index}
                    className="news-card"
                  >
                    <img
                      src={`https://officialagrogis.s3.amazonaws.com/${item.image}`}
                      alt={item.title}
                    />
                    <div className="news-card__info">
                      <h3 className="news-card__title">{item.title}</h3>
                      <p className="news-item__description">
                        {item.description}{" "}
                      </p>
                      <button className="read-more-btn">{t("readMore")}</button>
                    </div>
                  </Link>
                ))}
            </div>
          </section>
          <section className="latest-news">
            <div className="feeds-header">
              <h2>{t("trendingCourses")}</h2>
              <Link to="/farmer/academy" className="view-all-btn">
                {t("viewAll")}
              </Link>
            </div>
            <div className="latest-news-container">
              {courses && courses.length === 0 && (
                <NoNewsComponent
                  pTag={t("noCoursesAvailable")}
                  icon={
                    <FaBookOpen
                      size={48}
                      style={{ display: "inline" }}
                      color="#339475"
                    />
                  }
                  h2Tag={t("noCoursesTitle")}
                />
              )}

              {courses &&
                courses.map((item, index) => (
                  <Link
                    to={`/farmer/academy/details/${item.id}`}
                    key={index}
                    className="news-card"
                  >
                    <img
                      src={`https://officialagrogis.s3.amazonaws.com/${item.image}`}
                      alt={item.title}
                    />
                    <div className="news-card__info">
                      <h3 className="news-card__title">{item.title}</h3>
                      <p className="news-card__description">
                        {item.description}
                      </p>
                      <button className="read-more-btn">{t("readMore")}</button>
                    </div>
                  </Link>
                ))}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default FarmerHome;
