import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { Slide } from "react-slideshow-image";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { API, graphqlOperation } from "aws-amplify";
import { listFarmDetails } from "../../../../graphql/queries";
import { GiChestnutLeaf, GiForest } from "react-icons/gi";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { FaLeaf, FaTree } from "react-icons/fa";
import { fetchIssuesDetails } from "../../../fetches/FetchFarmDetails";
import { useTranslation } from "react-i18next";

const FarmSlider = ({ polygons, issues, loadingFarmDetails }) => {
  const { t } = useTranslation();
  // const [polygons, setPolygons] = useState([]);
  const [farmerID, setFarmerID] = useState("yourFarmerID"); // Set your farmer ID here
  const navigate = useNavigate();

  const issuesCount = (farmID) => {
    const issuesCount = issues.filter(
      (issue) => issue.farmDetailsID === farmID
    );
    return issuesCount.length;
  };

  console.log(polygons);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="farms-slider" style={{ marginRight: "5px" }}>
      {loadingFarmDetails ? (
        <div className="flex justify-center items-center h-full">
          <h2 className="text-center">{t("loading")}...</h2>{" "}
        </div>
      ) : polygons.length === 0 ? (
        <div className="flex justify-center items-center h-full">
          <h2 className="text-center">
            {t("you do not have registered a farm yet")}
          </h2>{" "}
        </div>
      ) : (
        <Slider {...settings}>
          {polygons.map((farm, index) => (
            <div key={index}>
              <div className="flex justify-between farm-slider-heder">
                <section className="farm-slider-header-left">
                  <img
                    src={"/assets/icons/placeholder.png"}
                    alt="wind"
                    className="w-8 h-8"
                  />

                  <div className="home-cards">
                    <h3>{farm.name}</h3>
                  </div>
                </section>

                <button
                  className="p-1 bg-orange-400 text-white text-sm rounded-md"
                  onClick={() => navigate(`/myfarm/`)}
                >
                  {t("see more")}
                </button>
              </div>
              <div className="flex flex-col gap-5 mt-5 weather-card-body">
                <section className="flex justify-around">
                  <div className="flex space-x-2 justify-center items-center">
                    <img
                      src="/assets/icons/map.png"
                      alt="wind"
                      className="w-8 h-8"
                    />
                    <p className="text-xl font-semibold">
                      {farm.area && farm.area.toFixed(2)} Ha
                    </p>
                  </div>

                  <div className="flex flex-col  justify-center items-end">
                    <div className="flex space-x-2 items-center">
                      <p className="text-xs">{t("start date")}</p>
                      <p className="text-xs text-orange-300 font-semibold">
                        {farm.startDate ? farm.startDate : "-"}
                      </p>
                    </div>
                    <div className="flex space-x-2  items-center">
                      <p className="text-xs">{t("expected harvest")}</p>
                      <p className="text-xs text-blue-400 font-semibold">
                        {farm.harvestDate ? farm.harvestDate : "-"}
                      </p>
                    </div>
                  </div>
                </section>

                <section className="flex justify-around">
                  <div className="flex flex-col items-center">
                    <img
                      src="/assets/icons/sprout.png"
                      alt="sprout"
                      className="w-8 h-8"
                    />
                    <p className="text-sm text-slate-400">{t("crop")}</p>
                    <p className="text-xl font-semibold">{farm.crop}</p>
                  </div>
                  <div className="flex flex-col items-center">
                    <img
                      src="/assets/icons/problem-solving.png"
                      alt="wind"
                      className="w-8 h-8"
                    />
                    <p className="text-sm text-slate-400">{t("issues")}</p>
                    <p className="text-xl font-semibold">
                      {issuesCount(farm.id)}
                    </p>
                  </div>
                </section>
              </div>
            </div>
          ))}
        </Slider>
      )}
    </div>
  );
};

export default FarmSlider;
