import React from "react";
import "./nav.css";
import { useTranslation } from "react-i18next";
// import { AiOutlineHome, AiOutlineInbox, AiOutlineCalendar, AiOutlineUser, AiOutlineSettings, AiOutlineChrome, AiOutlineMedicineBox, AiOutlineUserAdd } from 'react-icons/ai';
import { GrOverview } from "react-icons/gr";
import {
  MdOutlinePestControl,
  MdOutlineSatelliteAlt,
  MdSensors,
} from "react-icons/md";

import {
  AiOutlineChrome,
  AiOutlineClose,
  AiOutlineHome,
  AiOutlineMenu,
} from "react-icons/ai";
import { SiForestry, SiHtmlacademy } from "react-icons/si";
import {
  GiDustCloud,
  GiGroundbreaker,
  GiHotSurface,
  GiLevelThreeAdvanced,
  GiPlantSeed,
} from "react-icons/gi";
import { TiWeatherStormy } from "react-icons/ti";
import { GiFarmTractor } from "react-icons/gi";
import { CiGlobe, CiImageOn } from "react-icons/ci";
import { NavLink } from "react-router-dom";
import { CiLocationOn } from "react-icons/ci";
import { BsCloudSnow } from "react-icons/bs";
import { GiDeliveryDrone } from "react-icons/gi";
import { GoIssueOpened } from "react-icons/go";
import { FaHandsHelping, FaNewspaper } from "react-icons/fa";
import { SlDocs, SlGlobeAlt } from "react-icons/sl";
import { IoTelescopeOutline, IoWaterOutline } from "react-icons/io5";
import { BiGitPullRequest, BiSupport } from "react-icons/bi";
import { BiAnalyse } from "react-icons/bi";
import { IoIosLeaf } from "react-icons/io";
import useClickOutside from "./useClickOutside";

const FarmerSidebar = () => {
  const [showImagery, setShowImagery] = React.useState(true);
  const [showWeather, setShowWeather] = React.useState(true);
  const [showSoil, setShowSoil] = React.useState(true);
  const [sidebarActive, setSidebarActive] = React.useState(false);
  const { t } = useTranslation();

  const ref = useClickOutside(() => {
    setSidebarActive(false);
  });

  //fetch the package name from the local storage
  const packageName = localStorage.getItem("packageName");

  const showImageryTabs = () => {
    setShowImagery(!showImagery);
  };
  const showWeatherTabs = () => {
    setShowWeather(!showWeather);
  };

  const showSoilTabs = () => {
    setShowSoil(!showSoil);
  };

  const toggleSidebar = () => {
    setSidebarActive(!sidebarActive);
  };

  return (
    <>
      <div
        style={{
          position: "absolute",
          zIndex: 999,
          top: "14px",
          marginLeft: "10px",
        }}
        className="sidebar-toggle"
        onClick={toggleSidebar}
      >
        <AiOutlineMenu size={30} color="white" />
      </div>
      <div
        ref={ref}
        className={`sidebar-container ${sidebarActive ? "active" : ""}`}
      >
        <div
          style={{ position: "absolute", right: "14px", top: "10px" }}
          className="sidebar-toggle"
          onClick={toggleSidebar}
        >
          <AiOutlineClose size={30} color="black" />
        </div>
        <div
          className="
        h-20 md:h-20 lg:h-20 xl:h-20 2xl:h-20  flex items-center justify-center
        "
        >
          <img
            className="w-full px-5"
            src={"/Logo/PNG/leftbar.png"}
            alt="logo"
          />
        </div>
        <div className="tabs" onClick={toggleSidebar}>
          <NavLink to="/farmer" className="tab">
            <AiOutlineHome />
            <span>{t("homeTab")}</span>
          </NavLink>
          <NavLink to="/myfarm" className="tab">
            <GiFarmTractor />
            <span>{t("myFarmTab")}</span>
          </NavLink>

          <div className="tab dropdown" onClick={showWeatherTabs}>
            <div className="dropdown-icon">
              <TiWeatherStormy />
              <span>{t("weatherTab")}</span>
            </div>
            <div className="arrow-icon">
              <span>{showWeather ? "▲" : "▼"}</span>
            </div>
          </div>

          {showWeather && (
            <div className="imagery-tabs">
              <NavLink to="/stations" className="tab disabled">
                <CiLocationOn />
                <span>{t("stationsTab")}</span>
              </NavLink>

              <NavLink
                to="/weather"
                className="tab"
                title="Virtual Weather Station"
              >
                <BsCloudSnow />
                <span>VWS</span>
              </NavLink>
            </div>
          )}

          {/* <NavLink to='/iot' className='tab'>
          <IoTelescopeOutline />
          <span>{t('iotTab')}</span>
        </NavLink> */}

          <div className="tab dropdown" onClick={showImageryTabs}>
            <div className="dropdown-icon">
              <CiImageOn />
              <span>{t("imageryTab")}</span>
            </div>
            <div className="arrow-icon">
              <span>{showImagery ? "▲" : "▼"}</span>
            </div>
          </div>

          {showImagery && (
            <div className="imagery-tabs">
              <NavLink
                to="/landsat"
                className={
                  packageName === "AGROGIS Starter" ? "tab" : "tab disable"
                }
              >
                <MdOutlineSatelliteAlt />
                <span>{t("landsatTab")}</span>
              </NavLink>

              <NavLink to="/sentinel" className="tab disable">
                <MdOutlineSatelliteAlt />
                <span>{t("sentinel2Tab")}</span>
              </NavLink>

              <NavLink to="/planet" className="tab ">
                <MdOutlineSatelliteAlt />
                <span>{t("planetScopeTab")}</span>
              </NavLink>

              <NavLink to="/drone" className="tab disabled">
                <GiDeliveryDrone />
                <span>{t("droneServicesTab")}</span>
              </NavLink>

              <NavLink to="/biomass" className="tab">
                <IoIosLeaf />
                <span>{t("biomassTab")}</span>
              </NavLink>

              <NavLink to="/lst" className="tab">
                <GiHotSurface />
                <span>{t("lstTab")}</span>
              </NavLink>

              <NavLink to="/swc" className="tab">
                <IoWaterOutline />
                <span>{t("swcTab")}</span>
              </NavLink>

              {/* <NavLink to='/world' className="tab disabled" disabled>
                <SlGlobeAlt />
                <span>{t('worldViewTab')}</span>
              </NavLink> */}
            </div>
          )}

          <NavLink to="/advanced" className="tab">
            <GiLevelThreeAdvanced />
            <span>{t("aco")}</span>
          </NavLink>

          <div className="tab dropdown" onClick={showSoilTabs}>
            <div className="dropdown-icon">
              <GiGroundbreaker />
              <span>{t("soilMonitoringTab")}</span>
            </div>
            <div className="arrow-icon">
              <span>{showSoil ? "▲" : "▼"}</span>
            </div>
          </div>

          {showSoil && (
            <div className="imagery-tabs">
              <NavLink to="/sensors" className="tab disabled" disabled>
                <MdSensors />
                <span>{t("soilSensorsTab")}</span>
              </NavLink>

              {/* <NavLink to='/analysis' className='tab'>
              <BiAnalyse />
              <span>{t('soilAnalysisTab')}</span>
            </NavLink> */}

              {/* Add other soil tabs here */}
            </div>
          )}

          {/* 
<NavLink to='/support' className="tab">
          <BiSupport />
          <span>{t('decisionSupportTab')}</span>
        </NavLink> */}

          <NavLink to="/requests" className="tab disabled">
            <BiGitPullRequest />
            <span>{t("droneRequestTab")}</span>
          </NavLink>

          <NavLink to="/damages" className="tab">
            <SiForestry />
            <span>{t("cropDamagesTab")}</span>
          </NavLink>

          <NavLink to="/diseases" className="tab">
            <MdOutlinePestControl />
            <span>{t("pestsAndDiseasesTab")}</span>
          </NavLink>

          <NavLink to="/issues" className="tab">
            <GoIssueOpened />
            <span>{t("issuesTab")}</span>
          </NavLink>

          <NavLink to="/operations" className="tab">
            <GiPlantSeed />
            <span>{t("cropOperationsTab")}</span>
          </NavLink>
        </div>

        <div className="bg-white sidebar-footer" onClick={toggleSidebar}>
          <NavLink to="/farmer/news" className="sidebar-footer-icon">
            <FaNewspaper />
            <span>{t("news")}</span>
          </NavLink>
          <NavLink to="/farmer/academy" className="sidebar-footer-icon">
            <SiHtmlacademy />
            <span>{t("academy")}</span>
          </NavLink>
          <NavLink to="/farmer/help" className="sidebar-footer-icon">
            <FaHandsHelping />
            <span>{t("help")}</span>
          </NavLink>
        </div>
      </div>
    </>
  );
};

export default FarmerSidebar;
